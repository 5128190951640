<script setup lang="ts">
interface Props {
  amount: string
  currency: string
}

const props = defineProps<Props>()

const { formatPrice } = useFormatPrice()
const formattedAmount = computed(() => formatPrice(props.amount, props.currency))
</script>

<template>
  <span class="text-body-bold-12">
    {{ formattedAmount }}
  </span>
</template>
